<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-9 col-sm-9">
            <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </div>
            </form>
          </div>
          <div class="col-3 col-sm-3">
            <button v-if="checkPermission.create" class="btn btn-primary" @click="showModalAdd = true">Add Quick Reply</button>
          </div>
        </div>
        <div class="table-responsive mt-2">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th>Content</th>
                <th>Type</th>
                <th colspan="4">#</th>
              </tr>
            </thead>
            <tbody v-loading="loading.qrlist">
              <tr v-for="(qr) in qrList" :key="qr.id">
                <td>{{ qr.title }}</td>
                <td>
                  <!-- <FeatherIcon type="image" size="14px" v-if="qr.body_type == 'image'" /> -->
                  <i class="el-icon-picture-outline" v-if="qr.body_type == 'image'"/>
                  <i class="el-icon-video-camera" v-if="qr.body_type == 'video'"/>
                  <i class="el-icon-document" v-if="qr.body_type == 'document'"/>
                  <span>
                    {{ qr.body_type == 'text' ? moreText(qr.body) : moreText(qr.caption || qr.filename) }}
                  </span>
                </td>
                <td>{{ qr.body_type }}</td>
                <td>
                  <div class="d-flex align-items-end">
                    <button v-if="checkPermission.update" class="btn btn-outline-primary btn-sm" @click="showEditQr(qr)">
                      <font-awesome icon="edit"/>
                    </button> &nbsp;
                    <button v-if="checkPermission.delete" class="btn btn-outline-primary btn-sm" @click="deleteQr(qr.id)">
                      <font-awesome icon="times"/>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty v-if="totalRow == 0" description="No data"></el-empty>
        </div>
        <b-pagination v-if="totalRow > 10" v-model="currentPage" :total-rows="totalRow" :per-page="10"/>
      </div>
      <b-modal v-if="showModalAdd" v-model="showModalAdd" title="Create New Quick Replies" hide-footer>
        <b-form ref="formAddQr" @submit.prevent="createQr" @reset="closeModalAdd">
          <b-form-group
            id="qr-add-title"
            label="Title"
            label-for="qr-add-title">
            <b-form-input
              id="qr-add-title"
              v-model="v$.form.title.$model"
              type="text"
              placeholder="Enter quick reply title"
              required />
            <b-form-invalid-feedback :state="!v$.form.title.$invalid" v-if="v$.form.title.$errors[0]">
              {{ $t('validation.' + v$.form.title.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="qr-add-type"
            label="Type"
            label-for="qr-add-type">
            <b-form-select @change="addTypeChanged" required v-model="v$.form.type.$model" :options="options_type" size="sm"></b-form-select>
            <b-form-invalid-feedback :state="!v$.form.type.$invalid" v-if="v$.form.type.$errors[0]">
              {{ $t('validation.' + v$.form.type.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="qr-add-file"
            v-if="['image', 'video', 'document'].includes(v$.form.type.$model)"
            label="Attachment"
            label-for="qr-add-file">
            <b-form-file
              ref="addQrFile"
              v-model="form.file"
              :accept="accepted_ext[v$.form.type.$model]"
              placeholder="Choose a file or drop it here."
              drop-placeholder="Drop file here."
              :required="['image', 'video', 'document'].includes(v$.form.type.$model)"
            ></b-form-file>
            <b-form-invalid-feedback :state="!v$.form.file.$invalid" v-if="v$.form.file.$errors[0]">
              {{ $t('validation.' + v$.form.file.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="qr-add-message"
            v-if="['text', 'image', 'video'].includes(v$.form.type.$model)"
            :label="v$.form.type.$model == 'text' ? 'Message' : 'Caption'"
            label-for="qr-add-message">
            <b-input-group>
              <template #prepend>
                <b-input-group-text >
                  <el-popover
                    placement="top"
                    width="380"
                    trigger="hover"
                  >
                    <picker @select="addEmoji($event, 'create')" size="16" :showPreview="false" />
                    <span slot="reference">
                      <FeatherIcon type="smile" style="width: 16px;" />
                    </span>
                  </el-popover>
                </b-input-group-text>
              </template>
              <b-form-textarea
                id="qr-add-message"
                ref="textarea_message_create"
                v-model="v$.form.message.$model"
                rows="6"
                max-rows="12"
                placeholder="Enter your message"
                required />
            </b-input-group>
            <b-form-invalid-feedback :state="!v$.form.message.$invalid" v-if="v$.form.message.$errors[0]">
              {{ $t('validation.' + v$.form.message.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex flex-row-reverse">
            <!-- <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button> -->
            <el-button @click="createQr" :loading="loading.add_qr" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
            <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
          </div>
        </b-form>
      </b-modal>

      <b-modal v-model="showModalEdit" title="Edit Quick Reply" hide-footer>
        <b-form ref="formAddQr" @submit.prevent="createQr" @reset="closeModalAdd">
          <b-form-group
            id="qr-add-title"
            label="Title"
            label-for="qr-add-title">
            <b-form-input
              id="qr-add-title"
              v-model="v$.form_edit.title.$model"
              type="text"
              placeholder="Enter quick reply title"
              required />
              <b-form-invalid-feedback :state="!v$.form_edit.title.$invalid" v-if="v$.form_edit.title.$errors[0]">
              {{ $t('validation.' + v$.form_edit.title.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="qr-add-type"
            label="Type"
            label-for="qr-add-type">
            <b-form-select @change="addTypeChanged" required v-model="v$.form_edit.body_type.$model" :options="options_type" size="sm"></b-form-select>
            <b-form-invalid-feedback :state="!v$.form_edit.body_type.$invalid" v-if="v$.form_edit.body_type.$errors[0]">
              {{ $t('validation.' + v$.form_edit.body_type.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="qr-add-file"
            v-if="['image', 'video', 'document'].includes(form_edit.body_type)"
            label="Attachment"
            label-for="qr-add-file">
            <b-form-file
              ref="addQrFile"
              v-model="form_edit.file"
              :accept="accepted_ext[form_edit.body_type]"
              placeholder="Choose a file or drop it here."
              drop-placeholder="Drop file here."
              :required="['image', 'video', 'document'].includes(form_edit.body_type)"
            ></b-form-file>
            <div class="mt-3">
              Current attachment:<br>
              <img v-if="form_edit.body_type == 'image'" :src="form_edit.body" height="100px"/>
              <iframe v-if="form_edit.body_type == 'video'" :src="form_edit.body" height="100px"/>
              <a v-else-if="form_edit.body_type == 'document'" :href="form_edit.body" target="_blank">View here</a>
            </div>
          </b-form-group>

          <b-form-group
            id="qr-add-message"
            v-if="['text', 'image', 'video'].includes(form_edit.body_type)"
            :label="form_edit.body_type == 'text' ? 'Message' : 'Caption'"
            label-for="qr-add-message">
            <b-input-group>
              <template #prepend>
                <b-input-group-text >
                  <el-popover
                    placement="top"
                    width="380"
                    trigger="hover"
                  >
                    <picker @select="addEmoji($event, 'edit')" size="16" :showPreview="false" />
                    <span slot="reference">
                      <FeatherIcon type="smile" style="width: 16px;" />
                    </span>
                  </el-popover>
                </b-input-group-text>
              </template>
              <b-form-textarea
                v-if="form_edit.body_type == 'text'"
                id="qr-add-message"
                ref="textarea_message_edit"
                v-model="form_edit.body"
                rows="6"
                max-rows="12"
                placeholder="Enter your message"
                required />
              <b-form-textarea
                v-else
                id="qr-add-message"
                ref="textarea_message_edit"
                v-model="form_edit.caption"
                rows="6"
                max-rows="12"
                placeholder="Enter your message"
                required />
            </b-input-group>
            <b-form-invalid-feedback :state="!v$.form_edit.body.$invalid" v-if="v$.form_edit.body.$errors[0]">
              {{ $t('validation.' + v$.form_edit.body.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex flex-row-reverse">
            <!-- <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button> -->
            <el-button @click="updateQr" :loading="loading.edit_qr" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
            <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import mime from 'mime';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { Picker } from 'emoji-mart-vue-2';

import qrApi from '../../../api/quickReplies';
import popupErrorMessages from '../../../library/popup-error-messages';
import base64File from '../../../library/base64File';

export default {
  name: 'QuickReplies',
  metaInfo: {
    title: 'Quick Reply',
  },
  components: {
    Picker,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        title: {
          required,
        },
        type: {
          required,
        },
        message: {
          required: requiredIf(this.form.type === 'text'),
        },
        file: {
          required: requiredIf(this.form.type !== 'text'),
        },
      },
      form_edit: {
        title: {
          required,
        },
        body_type: {
          required,
        },
        body: {
          required: requiredIf(this.form.type === 'text'),
        },
      },
    };
  },
  data() {
    return {
      loading: {
        add_qr: false,
        qrlist: false,
        edit_qr: false,
      },
      totalRow: 0,
      page: 1,
      showModalAdd: false,
      showModalEdit: false,
      search: '',
      qrList: [],
      qrCount: 0,
      loaderStack: 0,
      loader: null,
      form: {
        title: '',
        message: '',
        file: null,
        type: 'text',
      },
      form_edit: {},
      options_type: [
        { value: 'text', text: 'Text' },
        { value: 'image', text: 'Image' },
        { value: 'video', text: 'Video' },
        { value: 'document', text: 'Document' },
      ],
      accepted_ext: {
        image: '.png, .jpeg, .jpg',
        video: '.mp4',
        document: '.png, .pdf, .jpeg, .jpg, .mp4, .xlsx, .xls, .doc, .docx, .txt',
      },
      last_index: null,
      company_id: this.$store.state.company.activeCompany.id,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.loadList();
      },
    },
    workspaces() {
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  async mounted() {
    this.showLoader();
    await this.loadList();
    this.hideLoader();
  },
  methods: {
    doSearch() {

    },
    async loadList() {
      this.loading.qrlist = true;
      const response = await qrApi.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.page,
        company_id: this.company_id,
      }).catch(() => {});
      this.loading.qrlist = false;
      await popupErrorMessages(response);
      this.qrList = response.data.rows;
      this.totalRow = response.data.total;
    },
    async createQr() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      let msg_formatted = this.form.message;
      const {
        type,
        file,
        title,
        message,
      } = this.form;
      if (['image', 'video', 'document'].includes(type)) {
        msg_formatted = await base64File(file);
        const fileExt = `.${mime.getExtension(file.type)}`;
        const accepted = this.accepted_ext[type].split(',').map((v) => v.trim());
        console.log(accepted, fileExt);
        if (!accepted.includes(fileExt)) {
          this.$message({
            message: this.$t('general.error.file_not_supported'),
            type: 'warning',
            duration: 10 * 1000,
          });
          return;
        }
      }
      this.loading.add_qr = true;
      const response = await qrApi.create({
        company_id: this.company_id,
        workspace_id: this.activeWorkspace._id,
        title,
        type,
        caption: ['image', 'video'].includes(type) ? message : null,
        body: msg_formatted,
        filename: type === 'document' ? file.name : null,
      }).catch(() => {});
      this.loading.add_qr = false;
      await popupErrorMessages(response);
      this.$message({
        message: this.$t('qr.success.add'),
        type: 'success',
      });
      this.resetFormAdd();
      this.showModalAdd = false;
      this.loadList();
    },
    moreText(string) {
      return string && string.length > 50 ? `${string.substr(0, 40)}...` : string;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    resetFormAdd() {
      this.form = {
        title: '',
        type: 'text',
        file: null,
        message: '',
      };
    },
    closeModalAdd() {
      this.resetFormAdd();
      this.showModalAdd = false;
    },
    addTypeChanged(value) {
      if (['image', 'video', 'document'].includes(value)) {
        setTimeout(() => {
          if (this.$refs.addQrFile) this.$refs.addQrFile.reset();
        }, 50);
      }
    },
    deleteQr(id) {
      this.$confirm(this.$t('qr.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await qrApi.delete({ id, workspace_id: this.activeWorkspace._id, company_id: this.company_id })
              .catch(() => {});
            await popupErrorMessages(response);

            this.$message({
              message: this.$t('qr.success.delete'),
              type: 'success',
            });

            this.search = '';
            this.loadList();
            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    showEditQr(obj) {
      this.showModalEdit = true;
      this.form_edit = _.cloneDeep(obj);
    },
    async updateQr() {
      this.v$.form_edit.$touch();
      if (this.v$.form_edit.$error) return;
      if (['image', 'video', 'document'].includes(this.form_edit.body_type)) {
        if (this.form_edit.file) {
          this.form_edit.body = await base64File(this.form_edit.file);
          const fileExt = `.${mime.getExtension(this.form_edit.file.type)}`;
          const accepted = this.accepted_ext[this.form_edit.body_type].split(',').map((v) => v.trim());
          if (!accepted.includes(fileExt)) {
            this.$message({
              message: this.$t('general.error.file_not_supported'),
              type: 'warning',
              duration: 10 * 1000,
            });
            return;
          }
        }
      }
      this.loading.edit_qr = true;
      this.form_edit.type = this.form_edit.body_type;
      this.form_edit.company_id = this.company_id;
      const response = await qrApi.update(this.form_edit).catch(() => {});
      this.loading.edit_qr = false;
      await popupErrorMessages(response);
      this.$message({
        message: this.$t('qr.success.edit'),
        type: 'success',
      });
      this.resetFormEdit();
      this.showModalEdit = false;
      this.loadList();
    },
    resetFormEdit() {
      this.form_edit = {};
    },
    addEmoji(emoji, type) {
      if (type === 'create') {
        const pos = this.$refs.textarea_message_create.selectionStart;
        const start_str = this.v$.form.message.$model.substring(0, pos);
        const end_str = this.v$.form.message.$model.substring(pos, this.v$.form.message.$model.length);
        this.v$.form.message.$model = [start_str, emoji.native, end_str].join('');
      } else if (type === 'edit') {
        if (this.form_edit.body_type === 'text') {
          const pos = this.$refs.textarea_message_edit.selectionStart;
          const start_str = this.v$.form_edit.body.$model.substring(0, pos);
          const end_str = this.v$.form_edit.body.$model.substring(pos, this.v$.form_edit.body.$model.length);
          this.v$.form_edit.body.$model = [start_str, emoji.native, end_str].join('');
        } else {
          const pos = this.$refs.textarea_message_edit.selectionStart;
          const start_str = this.form_edit.caption.substring(0, pos);
          const end_str = this.form_edit.caption.substring(pos, this.form_edit.caption.length);
          this.form_edit.caption = [start_str, emoji.native, end_str].join('');
        }
      }
    },
  },
};
</script>
