import api from '../library/axios';

export default {
  getList({
    workspace_id,
    page,
    search,
    search_by,
    company_id,
  }) {
    return api({
      method: 'get',
      url: `/api/quick-replies/${workspace_id}/${company_id}`,
      params: {
        page,
        search,
        search_by,
      },
    });
  },
  create({
    type,
    title,
    body,
    caption,
    workspace_id,
    company_id,
    filename,
  }) {
    return api({
      method: 'post',
      url: `/api/quick-replies/${workspace_id}/${company_id}`,
      data: {
        type,
        title,
        body,
        caption,
        filename,
      },
    });
  },
  delete({
    workspace_id,
    company_id,
    id,
  }) {
    return api({
      method: 'delete',
      url: `/api/quick-replies/${workspace_id}/${company_id}/${id}`,
    });
  },
  /* eslint-disable no-param-reassign */
  update(body) {
    const { id, workspace_id, company_id } = body;
    delete body.id;
    delete body.workspace_id;
    return api({
      method: 'patch',
      url: `/api/quick-replies/${workspace_id}/${company_id}/${id}`,
      data: body,
    });
  },
};
